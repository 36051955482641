<template>
    <div class="container contact">
        <div class="columns">
            <div class="column is-3 text-block">
                <h1 class="text-block--title">{{ $t(`contact.textBlock.title`) }}</h1>

                <p class="text-block--text" v-html="$t(`contact.textBlock.text`)"></p>
            </div>
            <div class="column is-5 is-offset-2" v-if="formSuccess">
                <h2>{{ $t(`contact.form.success`) }}</h2>
            </div>
            <div class="column is-5 is-offset-2" v-if="!formSuccess">
                <form class="form" @submit.prevent="submitForm">
                    <input type="text" :placeholder="$t(`contact.form.name`)" class="form-field" :aria-label="$t(`contact.form.name`)" v-model="form.name" required>
                    <input type="email" :placeholder="$t(`contact.form.email`)" class="form-field" :aria-label="$t(`contact.form.email`)" v-model="form.email">
                    <input type="text" :placeholder="$t(`contact.form.phone`)" class="form-field" :aria-label="$t(`contact.form.phone`)" v-model="form.phone">
                    <textarea :aria-label="$t(`contact.form.message`)" id="" cols="30" rows="10" :placeholder="$t(`contact.form.message`)" class="form-field" v-model="form.message" required></textarea>
                    
                    <small class="has-text-danger" v-if="formError">{{ formError }}</small>
                    <button type="submit" class="loup-button" :disabled="sending">{{ sending ? $t(`contact.form.sending`) : $t(`contact.form.send`) }}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "Contact",
        data: function () {
            return {
                sending: false,
                formSuccess: null,
                formError: '',
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    token: '',
                    lang: ''
                }
            }
        },
        methods: {
            submitForm() {
                if (!this.form.email && !this.form.phone) {
                    this.formError = this.$t(`contact.form.phoneEmailError`);
                    return;
                }

                this.sending = true

                this.$recaptcha('login').then((token) => {
                    this.form.token = token

                    if (this.form.token.length > 0) {
                        this.form.lang = this.$i18n.locale
                        axios
                            .post('https://loupsolitaire.be/contactmail.php', this.form)
                            .then(response => {
                                this.sending = false
                                if(response.data.success === "true") {
                                    this.formSuccess = true;
                                }
                            })
                            .catch(() => {
                                this.formError = this.$t(`error.send`);
                            })
                    } else {
                        this.formError = this.$t(`error.send`);
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        height: 70vh;
        display: flex;
        align-items: center;

        background-image: url("../assets/shapes/background-watermark.svg");
        background-repeat: no-repeat;
        background-size: 75% 100%;
        background-position: right;

        @media screen and(max-width: 769px) {
            align-items: unset;
            margin-bottom: 14vh;
        }
    }

    .text-block {
        align-self: center;
    }

    .loup-button {
        margin-top: 1.5rem;
        font-weight: 900;
    }
</style>
